<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="countries.items"
      item-key="id"
      :loading="isLoading"
      loading-text="Wird geladen"
      :search="search"
      no-data-text="keine Daten vorhanden"
      no-results-text="keine Suchergebnisse"
      locale="de-de"
      :items-per-page="25"
      class="elevation-1 color_table"
      :footer-props="{
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Einträge pro Seite',
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="showConfirmBar(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" tile @click="getData"> Neu laden </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="editVisible"
    >
      <EditCountry :country="selectedItem"></EditCountry>
    </v-dialog>

    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie das Land wirklich löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import EditCountry from "../../dialogs/Countries/EditCountry.vue";
export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_countries_data", () => {
      this.getData();
    });
    this.$root.$on("close_edit_country", () => {
      this.selectedItem = null;
      this.editVisible = false;
      this.getData();
    });
  },
  data() {
    return {
      confirmBar: false,
      selectedItem: null,
      editVisible: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Länder-Kennung", value: "key" },
        { text: "Name", value: "name" },
        { text: "Aktionen", value: "actions", sortable: false },
      ],
      search: "",
    };
  },
  computed: {
    countries() {
      return this.$store.state.countries.all;
    },
    isLoading() {
      return this.$store.state.countries.all.loading;
    },
  },
  methods: {
    async getData() {
      await this.$store.dispatch("countries/getAll");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    editItem(item) {
      this.selectedItem = item;
      console.log(this.selectedItem);
      if (this.selectedItem == null) return;
      this.editVisible = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;
      this.$store.dispatch("countries/delete", this.selectedItem).then(() => {
        this.$root.$emit("reload_countries_data");
        this.closeConfirmBar();
      });
    },
  },
  components: { EditCountry },
};
</script>
