import {
    authHeader
} from '../helpers';

export const ipnsService = {
    getAll,
};


const route = '/api/IPN'

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route, requestOptions, { mode: 'no-cors'}).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('vue-token');
                location.reload(true);               
            }

            const error = (data && data.message) || response.statusText;
            this.$store.dispatch("alert/error", error);
            return Promise.reject(error);
        }

        return data;
    });
}