<template>
    <v-container fluid>
            <v-row>
                <v-col col="12">
                    <h3>Systeminformationen</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12">
                    <ServiceAvailability></ServiceAvailability>

                    
                </v-col>
            </v-row>            
    </v-container>
</template>

<script>

import ServiceAvailability from "../widgets/ServiceAvailability.vue"

export default {    
    data() {
        return {
            
        }
    },
    components: {
        ServiceAvailability
    },
    computed: {
        versionInfo() {
            return process.env.VUE_APP_VERSION;
        },
        commitInfo() {
            return process.env.VUE_APP_COMMIT_TAG;
        }
    }
}
</script>
