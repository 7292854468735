import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from "../components/views/Dashboard"
import EMailSettings from "../components/views/EMailSettings.vue"
import SystemSettings from "../components/views/SystemSettings.vue"
import SystemInfo from "../components/views/SystemInfo.vue"

import MyCoaches from "../components/views/coaches/MyCoaches"

import Categories from "../components/views/categories/Categories"
import CoachDetails from "../components/views/coaches/CoachDetails.vue"; 
import Countries from "../components/views/countries/Countries"
import LockRequests from "../components/views/lockRequests/LockRequests"

import Products from "../components/views/digistore/Products"
import EditProduct from "../components/views/digistore/EditProduct"
import IPNs from "../components/views/digistore/IPNs"

import Unauthorized from "../components/views/Unauthorized.vue"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Dashboard,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/coaches',
    name: 'Coaches',
    component: MyCoaches,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/coach/:id',
    name: 'CoachDetails',
    component: CoachDetails,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: Categories,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/countries',
    name: 'countries',
    component: Countries,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SystemSettings,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/email',
    name: 'email',
    component: EMailSettings,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/info',
    name: 'info',
    component: SystemInfo,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: '/error',
    name: "error",
    component: Unauthorized,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: '/lockRequests',
    name: 'lockRequests',
    component: LockRequests,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/products',
    name: 'products',
    component: Products,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/product/:id',
    name: 'editProduct',
    component: EditProduct,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/ipns',
    name: 'ipns',
    component: IPNs,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      isAuthenticated: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString()
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
    } else if (Vue.$keycloak.hasResourceRole(process.env.VUE_APP_KEYCLOAK_ROLE)) {
      // The user was authenticated, and has the app role
      Vue.$keycloak.updateToken(70)
        .then(() => {
          localStorage.setItem("vue-refresh-token",  Vue.$keycloak.refreshToken);
          next()
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      localStorage.setItem("vue-token", Vue.$keycloak.token);
      // The user was authenticated, but did not have the correct role
      // Redirect to an error page
      //window.location.assign("/#/error");
      next();
    }
  } else {
    localStorage.setItem("vue-token",  Vue.$keycloak.token);
    // This page did not require authentication
    next()
  }
})

export default router
