<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h3>Themengebiete verwalten</h3>
                    <span>Verwalten Sie die Themengebiete für die Coaches.</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CategoriesTable></CategoriesTable>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <NewCategory></NewCategory>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import CategoriesTable from "../../tables/Categories/CategoriesTable.vue"
import NewCategory from "../../dialogs/Categories/NewCategory.vue"


export default ({
    components: {
        CategoriesTable,
        NewCategory
    }
})
</script>
