<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="coaches.items"
      item-key="id"
      :loading="isLoading"
      loading-text="Wird geladen"
      :search="search"
      no-data-text="keine Daten vorhanden"
      no-results-text="keine Suchergebnisse"
      locale="de-de"
      class="elevation-1 color_table"
      :footer-props="{
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Einträge pro Seite',
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.profilePictureThumbnailUrl="{ item }">
        <v-avatar size="36px" color="accent">
          <img
            v-if="item.profilePictureThumbnailUrl != null"
            :alt="item.firstName + ' ' + item.lastName"
            :src="frontendUrl(item.profilePictureThumbnailUrl)"
            lazy-src="https://picsum.photos/id/11/10/6"
            contain
          />
          <span
            v-else
            color="white"
            v-text="item.firstName[0] + item.lastName[0]"
          ></span>
        </v-avatar>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>{{ parseDate(item.createdAt) }}</span>
      </template>

      <template v-slot:item.isPublic="{ item }">
        <v-chip v-if="item.isPublic" color="green">Freigeschaltet</v-chip>
        <v-chip v-else color="grey">Inaktiv</v-chip>
      </template>

      <template v-slot:item.isLocked="{ item }">
        <v-chip v-if="item.isLocked" color="red">Gesperrt</v-chip>
      </template>

      <template v-slot:item.description="{ item }">
        <span>{{ item.description.substring(1, 140) }}...</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="showConfirmBar(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn tile color="primary" @click="getData"> Neu laden </v-btn>
      </template>
    </v-data-table>

    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie die Coach-Daten wirklich löschen? Dies löscht NICHT den
        Benutzeraccount. Der Benutzeraccount muss über die Account Konsole von Keycloak gelöscht werden.
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<style scoped>
.v-data-table {
  width: 100% !important;
}
</style>

<script>
import moment from "moment";

export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_coaches_data", () => {
      this.getData();
    });
  },
  data() {
    return {
      confirmBar: false,
      selectedItem: null,
      headers: [
        { text: "Erstellt am", value: "createdAt" },
        { text: "Bild", value: "profilePictureThumbnailUrl" },
        { text: "Status", value: "isPublic" },
        { text: "Gesperrt", value: "isLocked" },
        { text: "Vorname", value: "firstName" },
        { text: "Nachname", value: "lastName" },
        { text: "Beschreibung", value: "description" },
        { text: "Aktion", value: "actions", sortable: false },
      ],
      search: "",
    };
  },
  computed: {
    coaches() {
      let data = this.$store.state.coaches.all;
      console.info(data);
      return data;
    },
    isLoading() {
      return this.$store.state.coaches.all.loading;
    },
  },
  methods: {
    frontendUrl(imgPath) {
      return process.env.VUE_APP_API_ROUTE + imgPath;
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    async getData() {
      await this.$store.dispatch("coaches/getAll");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;

      this.$store.dispatch("coaches/delete", this.selectedItem).then(() => {
        this.$root.$emit("reload_coaches_data");
        this.closeConfirmBar();
      });
    },
    editItem(item) {
      this.$router.push("/coach/" + item.id);
    },
  },
};
</script>
