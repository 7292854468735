<template>
  <v-row>
    <v-col>
      <v-dialog transition="dialog-bottom-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"
            >Neues Land anlegen</v-btn
          >
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark
              >Neues Land hinzufügen</v-toolbar
            >
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="Data.Key"
                        :rules="inputRules"
                        label="Länder-Kennung"
                        required
                      ></v-text-field>
                    </v-col>
                     <v-col cols="12" md="6">
                      <v-text-field
                        v-model="Data.Name"
                        :rules="inputRules"
                        label="Name"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Schließen</v-btn>
              <v-btn tile color="primary" @click="Save(dialog)">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      Data: {
        Name: "",
        Key: ""
      },
      inputRules: [
        (v) => !!v || "Pflichtfeld"
      ],
    };
  },
  methods: {
    Reset() {
      this.Data.Name = "";
      this.Data.Key = "";
    },
    async GetCountries() {
      await this.$store.dispatch("countries/getAll");
    },
    Save(dialog) {
      let dto = {
        Name: this.Data.Name,
        Key: this.Data.Key
      };

      this.$store.dispatch("countries/create", dto).then(() => {
        this.$root.$emit("reload_countries_data");
        dialog.value = false;
        this.Reset();
      });
    },
  },
};
</script>
