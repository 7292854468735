<template>
  <v-card style="padding: 1em; min-height: 800px; max-height: 800px;">
    <v-card-title class="text-h5">
      System Dienst Status
    </v-card-title>
    <v-card-content> 
        <iframe src="https://uptime.marcogriep.de/status/coach2find" width="100%" height="720px" frameborder="0"></iframe>
    </v-card-content>    
  </v-card>
</template>

<script>
export default {

};
</script>
