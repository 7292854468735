<template>
    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="ipns.items"
        item-key="id"
        :loading="isLoading"
        loading-text="Wird geladen"
        :search="search"
        no-data-text="keine Daten vorhanden"
        no-results-text="keine Suchergebnisse"
        locale="de-de"
        :items-per-page="100"
        class="elevation-1 color_table"
        :footer-props="{
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Einträge pro Seite',
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Suchen"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" tile @click="getData"> Neu laden </v-btn>
        </template>
      </v-data-table>

    </div>
  </template>
  
  <script>

  export default {
    created() {
      this.getData();
    },
    mounted() {
      this.$root.$on("reload_ipn_data", () => {
        this.getData();
      });
    },
    data() {
      return {
        headers: [
          { text: "ID", value: "id" },
          { text: "Datum", value: "createdAt" },
          { text: "Vorgang", value: "event" },
          { text: "Verarbeitet", value: "processed"},
          { text: "Gültig", value: "valid" },
          { text: "Error", value: "processError" },
          { text: "Order-ID", value: "orderId" },
          { text: "Produkt-ID", value: "productId" },
          { text: "Produkt", value: "productName" },
          { text: "Typ", value: "billingType" },
          { text: "Zahlungssequenz", value: "paySequenceNo" },
          { text: "E-Mail", value: "eMail" },
        ],
        search: "",
      };
    },
    computed: {
      ipns() {
        return this.$store.state.ipns.all;
      },
      isLoading() {
        return this.$store.state.ipns.all.loading;
      },
    },
    methods: {
      async getData() {
        await this.$store.dispatch("ipns/getAll");
      },
    },
  };
  </script>
  