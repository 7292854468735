<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="data.items"
      item-key="id"
      :loading="isLoading"
      loading-text="Wird geladen"
      :search="search"
      no-data-text="keine Daten vorhanden"
      no-results-text="keine Suchergebnisse"
      locale="de-de"
      :items-per-page="100"
      class="elevation-1 color_table"
      :footer-props="{
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Einträge pro Seite',
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.coachId="{ item }">
        <v-btn @click="navigate(item.coachId)" text>{{ item.coachId }}</v-btn>
      </template>

      <template v-slot:item.type="{ item }">
        <v-chip color="red" v-if="item.type == 0">Sperrung</v-chip>
        <v-chip color="green" v-else>Entsperrung</v-chip>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" tile @click="getData"> Neu laden </v-btn>
      </template>
    </v-data-table>
    

  </div>
</template>

<script>

export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_lockRequests_data", () => {
      this.getData();
    });
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Datum", value: "createdAt" },
        { text: "Coach ID", value: "coachId" },
        { text: "Typ", value: "type" },
        { text: "Grund der Sperrung", value: "reason" },
      ],
      search: "",
    };
  },
  computed: {
    data() {
      return this.$store.state.lockRequests.all;
    },
    isLoading() {
      return this.$store.state.lockRequests.all.loading;
    },
  },
  methods: {
    async getData() {
      await this.$store.dispatch("lockRequests/getAll");
    },
    navigate(coachId) {
      this.$router.push({ name: "CoachDetails", params: { id: coachId } });
    }
  },
};
</script>
