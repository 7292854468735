<template>
  <v-card>
    <v-toolbar color="primary" dark>Themengebiet editieren</v-toolbar>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="Data.name"
                :rules="inputRules"
                label="Name"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn text @click="Close()">Schließen</v-btn>
      <v-btn tile color="primary" @click="Save(dialog)">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      Data: {
        id: this.category.id,
        name: this.category.name,
      },
      inputRules: [(v) => !!v || "Pflichtfeld"],
    };
  },
  props: {
    category: null,
  },
  methods: {
    Reset() {
      this.Data.id = null;
      this.Data.name = "";
    },
    Close() {
      //this.Reset();
      this.$root.$emit("close_edit_category");
    },
    Save() {
      let dto = {
        id: this.Data.id,
        name: this.Data.name,
      };

      console.info(dto);

      this.$store.dispatch("categories/update", dto).then(() => {
        this.$root.$emit("reload_categories_data");
        this.Close();
      });
    },
  },
};
</script>
