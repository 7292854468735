<template>
    <v-container fluid>
            <v-row>
                <v-col col="12">
                    <h3>System Einstellungen</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12" v-if="Settings != null && Settings != undefined">
                     <v-switch
                        v-model="Settings.hasMaintanance"
                        :label="`Wartungsmodus: ${getMaintanance()}`"
                     ></v-switch>
                     <br/>
                     <v-text-field
                      v-model="Settings.logoURL"
                      label="Logo URL"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.companyName"
                      label="Company Name"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.street"
                      label="Straße"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.plz"
                      label="PLZ"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.city"
                      label="Stadt"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.country"
                      label="Country"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.phone"
                      label="Telefon"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.eMail"
                      label="E-Mail"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.facebookUrl"
                      label="Facebook"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.instagramUrL"
                      label="Instagram"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.twitterUrl"
                      label="Twitter"
                    ></v-text-field>

                    <v-btn tile color="primary" @click="Save()">Speichern</v-btn>
                </v-col>
            </v-row>            
    </v-container>
</template>

<script>
export default {    
  created() {
    this.reload();
  },
  mounted() {
    this.$root.$on("reload_settings_data", () => {
      this.reload();
    });
  },
  computed: {
    Settings() {
        let settings = this.$store.state.settings.all;
        return settings.items;
    },
    isLoading() {
      return this.$store.state.settings.all.isLoading;
    },
  },
  methods: {
    async Save() {
        await this.$store.dispatch("settings/update", this.Settings);
        this.$root.$emit("reload_settings_data");
    },
    getMaintanance() {
        if (this.Settings.hasMaintanance == null) {
            return "Aus";
        }
        return (this.Settings.hasMaintanance == true) ? "An" : "Aus";
    },
    reload() {
        this.$store.dispatch("settings/getAll");
    },
  },
}
</script>
