<template>
  <v-row>
    <v-col>
      <v-dialog transition="dialog-bottom-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"
            >Neues Produkt anlegen</v-btn
          >
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>Neues Produkt hinzufügen</v-toolbar>
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="Data.DigistoreProductId"
                        :rules="inputRules"
                        label="Produkt ID von Digistore"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="Data.Name"
                        :rules="inputRules"
                        label="Mitgliedschaft Name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="Data.Price"
                        :rules="inputRules"
                        label="Preis im Monat"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="Data.DigistoreUrl"
                        :rules="inputRules"
                        label="Digistore URL"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="Data.MonthDuration"
                        :rules="inputRules"
                        label="Laufzeit"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-tiptap v-model="Data.html" label="Beschreibung">
                      </v-tiptap>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-checkbox
                        v-model="Data.IsAvailable"
                        :rules="inputRules"
                        label="Ist verfügbar"
                        required
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-checkbox
                        v-model="Data.Highlight"
                        :rules="inputRules"
                        label="Als Highlight markieren"
                        required
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Schließen</v-btn>
              <v-btn tile color="primary" @click="Save(dialog)"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>
  
  <script>
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      Data: {
        DigistoreProductId: "",
        Name: "",
        Html: "",
        Price: 0,
        IsAvailable: false,
        DigistoreUrl: "",
        Highlight: false,
        MonthDuration: 1,
      },
      inputRules: [(v) => !!v || "Pflichtfeld"],
    };
  },
  methods: {
    Reset() {
      this.Data.Name = "";
      this.Data.DigistoreProductId = "";
      this.Data.Html = "";
      this.Data.Price = 0;
      this.Data.IsAvailable = false;
      this.Data.DigistoreUrl = "";
      this.Data.Highlight = false;
      this.Data.MonthDuration = 1;
    },
    async GetProducts() {
      await this.$store.dispatch("products/getAll");
    },
    Save(dialog) {
      this.$store.dispatch("products/create", this.Data).then(() => {
        this.$root.$emit("reload_products_data");
        dialog.value = false;
        dialog = false;
        this.Reset();
      });
    },
  },
  computed: {
    products() {
      return this.$store.state.products.all.items;
    },
  },
};
</script>
  