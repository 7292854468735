<template>
  <v-list nav dense>
   
    <v-list-item link>
      <v-list-item-icon>
        <v-icon>mdi-web</v-icon>
      </v-list-item-icon>
      <v-list-item-title
        ><router-link to="/">
          Dashboard
        </router-link></v-list-item-title
      >
    </v-list-item>

    
    <v-list-group prepend-icon="mdi-account">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title><a href="#">Coaches</a></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/coaches"
              >Coach Übersicht</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/lockRequests"
              >Sperranträge</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

    </v-list-group>

    <v-list-group prepend-icon="mdi-clipboard-list-outline">
       <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title><a href="#">Inhalts Moderation</a></v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/countries"
              >Länder</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/categories"
              >Themengebiete</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

    </v-list-group>

    <v-subheader><b>Integrationen</b></v-subheader>

    <v-list-group prepend-icon="mdi-account">
       <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title><a href="#">User Management</a></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><a href="https://sso.coach2find.de"  target="_blank"
              >Benutzer & Rollen verwalten</a
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

    </v-list-group>

    <!--<v-list-group prepend-icon="mdi-email-fast">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title><a href="#">Newsletter System</a></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><a href="https://newsletter.coach2find.de" target="_blank"
              >Newsletter System öffnen</a
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list-group>-->

    <!--<v-list-group prepend-icon="mdi-email-fast">
       <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title><a href="#">Blog System</a></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><a href="https://publish.coach2find.de/ghost" target="_blank"
              >Blog Beiträge</a
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list-group>-->
      
    <v-list-group prepend-icon="mdi-chart-areaspline">
       <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title><a href="#">Analytics</a></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><a href="https://analytics.coach2find.de" target="_blank"
              >Analytics öffnen</a
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list-group>


    <v-list-group prepend-icon="mdi-cash-sync">
       <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title><a href="#">Digistore Integration</a></v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/ipns"
              >Instant Payment Notifications</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/products"
              >Digistore Produkte Verknüpfen</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

    </v-list-group>

    <v-subheader><b>System</b></v-subheader>
    <v-list-group prepend-icon="mdi-wrench-cog-outline">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title><a href="#">Einstellungen</a></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/settings"
              >Allgemeine Einstellungen</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/email"
              >E-Mail Einstellungen</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><router-link to="/info"
              >Systeminformationen</router-link
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-subheader><b>Navigation</b></v-subheader>
    <v-list-item link>
      <v-list-item-icon>
        <v-icon>mdi-web</v-icon>
      </v-list-item-icon>
      <v-list-item-title
        ><a href="https://www.coach2find.de">
          Zur Hauptseite
        </a></v-list-item-title
      >
    </v-list-item>

     <v-list-item link>
      <v-list-item-icon>
        <v-icon>mdi-help</v-icon>
      </v-list-item-icon>
      <v-list-item-title
        ><a href="www.marcogriep.de" target="_blank">
          Support erhalten
        </a></v-list-item-title
      >
    </v-list-item>
  </v-list>
</template>

<script>
import { getUsername, getInitials } from "../../helpers";

export default {
  data() {
    return {
      communityVisible: false,
      helpVisible: false,
      downloadsVisible: false
    };
  },
  mounted() {
    
  },
  computed: {
    username() {
      return getUsername();
    },
    initials() {
      return getInitials();
    },
  },
  methods: {
    getLogoutURL() {
      return process.env.VUE_APP_KEYCLOAK_ENDSESSION_ROUTE;
    },

    revertVisibleCommunity() {
      this.communityVisible = this.communityVisible ? false : true;
    },
    revertVisibleHelp() {
      this.helpVisible = this.helpVisible ? false : true;
    },
    revertVisibleDownloads() {
      this.downloadsVisible = this.downloadsVisible ? false : true;
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: #3b3b3b;
  font-size: 14px;
}
.theme--light.v-list-item--active {
  background-color: #38633a !important;
}

.v-list-item--active.v-list-item--link.theme--light > div.v-list-item__title {
  color: white !important;
}

div.v-list-item__title > a {
  color: black;
    font-family: 'Roboto', sans-serif;
  font-weight: 300 !important;
  font-size: 14px;
}

div.v-list-item__title {
  color: black;
    font-family: 'Roboto', sans-serif;
  font-weight: 300 !important;
  font-size: 14px;
}

div.v-list-group.v-list-group--active.primary--text
  > div.v-list-group__items
  > div
  > div.v-list-item__title
  > a {
  color: #38633a !important;
  padding-left: 7%;
}
</style>

<style>
.theme--light.v-list-item--active {
    background-color: white !important; 
}

div.v-list-item__content div {
  color: #212832;
    font-family: 'Roboto', sans-serif;
  font-weight: 300 !important;
 font-size: 14px;
}

.v-list-group__items {
  border-left: thin #d4dae3 solid;
  margin-left: 1em;
}

.v-list-item__title {
    color: #212832;
    font-family: 'Roboto', sans-serif;
  font-weight: 300 !important;
 font-size: 14px;
}

.v-list-group__items div.v-list-item {
  margin-left: 2.4em;
}

</style>