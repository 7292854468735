import { lockRequestService } from '../services';

export const lockRequests = {
    namespaced: true,
    state: {
        all: {},
    },
    actions: {
        getAll({ commit }, id) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                lockRequestService.getAll(id)
                .then(
                    payload => {
                        commit('getAllSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getAllFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },

    },
    mutations: {


        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, payload) {
            state.all = { items: payload };
        },
        getAllFailure(state, error) {
            state.all = { error };
        }
    }
}