<template>
    <v-container fluid>
            <v-row>
                <v-col col="12">
                    <h3>E-Mail Einstellungen</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12" v-if="Settings != null && Settings != undefined">
                    <v-select id="provider" :items="items" label="Mail Provider" v-model="Settings.provider" item-text="text" item-value="value"></v-select>

                     <v-text-field
                      v-model="Settings.host"
                      label="Hostname"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.port"
                      label="Port"
                    ></v-text-field>

                    <v-switch
                        v-model="Settings.useSSL"
                        :label="`SSL: ${getSSL()}`"
                     ></v-switch>

                    <v-text-field
                      v-model="Settings.username"
                      label="Benutzername"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.password"
                      label="Password"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.fromName"
                      label="Von Name"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.fromAddress"
                      label="Von Adresse"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.templateId"
                      label="MailJet Template ID"
                    ></v-text-field>

                    <v-text-field
                      v-model="Settings.secretKey"
                      label="Secret Key"
                    ></v-text-field>
                    
                    <v-btn tile color="primary" @click="Save()">Speichern</v-btn>
                </v-col>
            </v-row>            
    </v-container>
</template>

<script>
export default {    
  created() {
    this.reload();
  },
  mounted() {
    this.$root.$on("reload_mailsettings_data", () => {
      this.reload();
    });
  },
  data() {
    return {
      items: [
        { text: "MailJet", value: 2 },
        { text: "SMTP", value: 1 },
        { text: "SendGrid", value: 3}
      ],
    }
  },
  computed: {
    Settings() {
        let settings = this.$store.state.mailsettings.all;
        return settings.items;
    },
    isLoading() {
      return this.$store.state.mailsettings.all.isLoading;
    },
  },
  methods: {
    async Save() {
        await this.$store.dispatch("mailsettings/update", this.Settings);
        this.$root.$emit("reload_mailsettings_data");
    },
    getSSL() {
        if (this.Settings.useSSL == null) {
            return "Aus";
        }
        return (this.Settings.useSSL == true) ? "An" : "Aus";
    },
    reload() {
        this.$store.dispatch("mailsettings/getAll");
    },
  },
}
</script>
