<template>
  <div>
    <v-container fluid style="margin: 0px; padding: 0px">
      <v-parallax
        src="https://ik.imagekit.io/8uiikipiqja/pexels-tirachard-kumtanom-733852_G5vvtsN5d.jpg"
        height="380"
      ></v-parallax>
    </v-container>
    <v-container style="margin-top: -24em">
      <v-row>
        <v-col md="12" sm="12">
          <v-card style="padding: 1em">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  <v-icon>mdi-pulse</v-icon>Hallo {{ username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" sm="12" lg="6">
          <v-card style="padding: 1em;  min-height: 800px; max-height: 800px;">
            <v-card-title class="text-h5"> Übersicht Coaches </v-card-title>

            <v-card-content>
              <span
                >Alle Coaches sortiert nach Anmeldedatum.</span
              >

              <CoachesTableOverview></CoachesTableOverview>
            </v-card-content>
          </v-card>
        </v-col>
        
        <v-col md="6" sm="12" lg="6">
          <ServiceAvailability></ServiceAvailability>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getUsername } from "../../helpers";

//import PacklistTableOverview from "./packlists/PacklistTableOverview.vue"
import ServiceAvailability from "../widgets/ServiceAvailability.vue";
import CoachesTableOverview from "./coaches/CoachesTableOverview.vue";

export default {
  created() {
    this.reload();
  },
  mounted() {
    this.$root.$on("reload_packlist_data", () => {
      this.reload();
    });
  },
  components: {
    ServiceAvailability,
    CoachesTableOverview,
  },
  data() {
    return {
      percent: 0,
      adProducts: [],
      AccessCode: "",
      confirmBar: false,
      selectedItem: null,
      headers: [
        { text: "Erstellt am", value: "createdAt" },
        { text: "Anzahl", value: "count" },
        { text: "Hersteller", value: "userGear.manufacturer.name" },
        { text: "Name", value: "userGear.name" },
        { text: "Kategorie", value: "userGear.gearCategory" },
        { text: "Gesamtgewicht", value: "totalGramm" },
        { text: "Gesamtvolumen", value: "totalVolume" },
        { text: "Trainer", value: "actions", sortable: false },
      ],
      search: "",

      tagChartGrammOptions: {
        chart: {
          title: "Aufteilung in Kategorien",
        },
        pieSliceText: "value",
      },
    };
  },

  computed: {
    isLoading() {
      return this.$store.state.packlists.all.isLoading;
    },
    isGearLoading() {
      return this.$store.state.usergear.all.loading;
    },
    username() {
      return getUsername();
    },
    
    gearLast() {
      return "";
    },
  },
  methods: {
    async reload() {
      if (this.id) {
        await this.$store.dispatch("coaches/getSingle", this.id);
      }
    },
    getAbsoluteImageUrl(image) {
      return process.env.VUE_APP_STRAPI_URL + image;
    },
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>
