<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h3>Länder verwalten</h3>
                    <span>Verwalten Sie die Verfügbaren Länder für die Coaches</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CountriesTable></CountriesTable>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <NewCountry></NewCountry>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import CountriesTable from "../../tables/Countries/CountriesTable.vue"
import NewCountry from "../../dialogs/Countries/NewCountry.vue"


export default ({
    components: {
        CountriesTable,
        NewCountry
    }
})
</script>
