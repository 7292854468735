<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h3>Anbindung der Digistore Produkte</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NewProduct></NewProduct>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="product in products.items" :key="product.id">
          <v-card :loading="isLoading" class="mx-auto my-12" max-width="374">
            <v-card-title
              >{{ product.name }} ({{
                product.digistoreProductId
              }})</v-card-title
            >
            <v-card-text>
              <v-row align="center" class="mx-0">
                <div class="my-4 text-subtitle-1">{{ product.price }} €</div>
              </v-row>
              <div v-html="product.html"></div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="info" @click="openUrl(product.digistoreUrl)"
                >Digistore öffnen</v-btn
              >
              <v-btn color="primary" @click="editItem(product)"
                >Bearbeiten</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NewProduct from "./../../dialogs/Products/NewProduct.vue";

export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_products_data", () => {
      this.getData();
    });
  },
  computed: {
    products() {
      let data = this.$store.state.products.all;
      console.info(data);
      return data;
    },
    isLoading() {
      return this.$store.state.products.all.loading;
    },
  },
  methods: {
    async getData() {
      await this.$store.dispatch("products/getAll");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;

      this.$store.dispatch("products/delete", this.selectedItem).then(() => {
        this.$root.$emit("reload_products_data");
        this.closeConfirmBar();
      });
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    editItem(item) {
      console.log(item);
      this.$router.push("/product/" + item.id);
    },
  },
  components: {
    NewProduct,
  },
};
</script>
