import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { coaches } from './coaches.module';
import { categories } from './categories.module';
import { settings } from './settings.module';
import { mailsettings } from './mailsettings.module';
import { countries } from './countries.module';
import { lockRequests } from './lockRequests.module';
import { products } from './products.module';
import { ipns } from './ipns.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        alert,
        coaches,
        categories,
        settings,
        mailsettings,
        countries,
        lockRequests,
        products,
        ipns
    }
});