<template>
  <v-row>
    <v-col>
      <v-dialog transition="dialog-bottom-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"
            >Neues Themengebiet anlegen</v-btn
          >
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark
              >Neues Themengebiet hinzufügen</v-toolbar
            >
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="Data.Name"
                        :rules="inputRules"
                        label="Name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-select
                        v-model="Data.TopCategoryId"
                        :items="selectionCategories"
                        item-text="name"
                        item-value="id"
                        label="Übergeordnetes Themengebiet"
                        required></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Schließen</v-btn>
              <v-btn tile color="primary" @click="Save(dialog)">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      Data: {
        Name: "",
        TopCategoryId: null
      },
      inputRules: [
        (v) => !!v || "Pflichtfeld"
      ],
    };
  },
  methods: {
    Reset() {
      this.Data.Name = "";
      this.TopCategoryId = null;
      this.TopCategory = null;
    },
    async GetCategories() {
      await this.$store.dispatch("categories/getAll");
    },
    Save(dialog) {
      let dto = {
        Name: this.Data.Name,
        TopCategoryId: this.Data.TopCategoryId,
        TopCategory: null
      };

      this.$store.dispatch("categories/create", dto).then(() => {
        this.$root.$emit("reload_categories_data");
        dialog.value = false;
        dialog = false;
        this.Reset();
      });
    },
  },
  computed: {
    categories() {
      return this.$store.state.categories.all.items;
    },
    selectionCategories() {
      let data = this.$store.state.categories.all.items;

      // Add an Null Entry at the beginning of data
      data.unshift({ id: null, name: "-" });
      return data;
    }
  },
};
</script>
