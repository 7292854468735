<template>
    <v-row>
      <v-col>
        <v-dialog transition="dialog-bottom-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on"
              >Neuer Sperrantrag anlegen</v-btn
            >
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark
                >Neuer Sperrantrag für Coach: {{ Data.firstName }}, {{ Data.lastName }}</v-toolbar
              >
              <v-card-text>
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="RequestData.Reason"
                          :rules="inputRules"
                          label="Grund für Sperrung (max. 100 Zeichen)"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Schließen</v-btn>
                <v-btn tile color="primary" @click="Save(dialog)">Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialog: false,
        valid: true,
        Data: {
            id: this.coach.id,
            firstName: this.coach.firstName,
            lastName: this.coach.lastName,
        },
        RequestData: {
            coachId: this.coach.id,
            Type: 0, //Lock
            Reason: "",
        },
        inputRules: [
          (v) => !!v || "Pflichtfeld"
        ],
      };
    },
    props: {
        coach: null,
    },
    methods: {
      Reset() {
        this.Data.Name = "";
      },
      Save(dialog) {
        if (!this.valid) {
          return;
        }
        this.$store.dispatch("coaches/lockRequest", this.RequestData).then(() => {
          this.$root.$emit("reload_coaches_data");
          dialog.value = false;
          dialog = false;
          this.Reset();
          this.$router.push("/coaches");
        });
      },
    },
  };
  </script>
  