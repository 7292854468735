import { productsService } from '../services';

export const products = {
    namespaced: true,
    state: {
        all: {},
        selected: {},
        default: {}
    },
    actions: {
        getAll({ commit }, id) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                productsService.getAll(id)
                .then(
                    payload => {
                        commit('getAllSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getAllFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },

        getSingle({ commit }, id) {
            commit('getRequest');

            return new Promise((resolve, reject) => {
                productsService.getSingle(id)
                .then(
                    payload => {
                        commit('getSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    } 
                )
            });            
        },


        delete({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                productsService.deleteExisting(dto)
                    .then(
                        payload => {
                            commit('defaultRequest', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultRequest', error);
                            reject(error)
                        }
                    );
            })
        },

        update({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                productsService.updateExisting(dto)
                    .then(
                        payload => {
                            commit('defaultRequest', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultRequest', error);
                            reject(error)
                        }
                    );
            })
        },
        
        create({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                productsService.createNew(dto)
                    .then(
                        payload => {
                            commit('defaultSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultFailure', error);
                            reject(error)
                        }
                    );
            })
        },
    },
    mutations: {

        defaultRequest(state) {
            state.default = {
                loading: true
            }; 
        },
        defaultSuccess(state, payload) {
            state.default = {
                item: payload,
                loading: false
            }; 
        },
        defaultFailure(state, error) {
            state.default = {
                error,
                loading: false
            }; 
        },

        getRequest(state) {
            state.selected = { loading: true };
        },
        getSuccess(state, payload) {
            state.selected = { item: payload };
        },
        getFailure(state, error) {
            state.selected = { error };
        },
        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, payload) {
            state.all = { items: payload };
        },
        getAllFailure(state, error) {
            state.all = { error };
        }
    }
}