<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h3>Alle Sperr- und Entsperranträge</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <LockRequestsTable></LockRequestsTable>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import LockRequestsTable from "../../tables/lockRequests/LockRequestsTable.vue"


export default ({
    components: {
        LockRequestsTable
    }
})
</script>
