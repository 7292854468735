<template>
  <div>
    <v-container fluid style="margin: 0px; padding: 0px">
      <v-parallax
        src="https://images.unsplash.com/photo-1488646953014-85cb44e25828?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80"
        height="380"
      ></v-parallax>
    </v-container>

    <v-container style="margin-top: -24em">
      <v-card style="height: 900px">
        <v-row style="margin-top: 1em; padding: 1em;">
          <v-col md="12" xs="12" lg="3" sm="12">
            <img
              v-if="coach.profilePictureThumbnailUrl != null"
              :alt="coach.firstName + ' ' + coach.lastName"
              :src="frontendUrl(coach.profilePictureUrl)"
              lazy-src="https://picsum.photos/id/11/10/6"
              contain
            />
            <span
              v-else
              color="white"
              v-text="coach.firstName[0] + coach.lastName[0]"
            ></span>
          </v-col>
          <v-col md="9" sm="12">
            <v-row>
              <v-card-title>
                {{ coach.firstName }} {{ coach.lastName }}
              </v-card-title>
              <v-card-text>
                {{ coach.description }}
              </v-card-text>

              <v-chip
                class="ma-2"
                color="green"
                text-color="white"
                v-if="coach.isPublic"
              >
                Freigeschaltet
              </v-chip>
              <template  v-else>
                <v-chip class="ma-2" color="grey" text-color="white">
                  Warte auf Freischaltung
                </v-chip>
                <v-btn text @click="Activate()">Jetzt freischalten</v-btn>
              </template>
              

              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                v-if="coach.isLocked"
              >
                GESPERRT
              </v-chip>
              <v-btn text @click="showConfirmBar()" v-if="coach.isLocked"
                >Entsperren</v-btn
              >
              
              <UnlockCoach :coach="coach" v-if="coach.isLocked"></UnlockCoach>
              <LockCoach :coach="coach" v-else></LockCoach>

              
            </v-row>
            <v-row>
              <v-col col="12">
                <v-alert
                  color="cyan"
                  border="left"
                  elevation="2"
                  colored-border
                  transition="scale-transition"
                >
                  <blockquote>{{ coach.callToAction }}</blockquote>
              </v-alert>
              </v-col>
            </v-row>
            <v-row>
                <v-col col="6">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Allgemeine Angaben
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="coach.firstName"
                              :rules="nameRules"
                              :counter="10"
                              label="Vorname"
                              disabled
                            ></v-text-field>
                          </v-col>

                          <v-col>
                            <v-text-field
                              v-model="coach.lastName"
                              :counter="10"
                              label="Nachname"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col col="6">
                            <v-text-field
                              v-model="coach.street"
                              :counter="10"
                              label="Straße & Hausnummer"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col col="3">
                            <v-text-field
                              v-model="coach.plz"
                              :counter="10"
                              label="PLZ"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col col="3">
                            <v-text-field
                              v-model="coach.city"
                              :counter="10"
                              label="Stadt"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col col="12">
                            <v-text-field
                              v-model="coach.country"
                              label="Land"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Kontakt
                      </v-expansion-panel-header>
                      <v-expansion-panel-content> </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Trainings Methoden
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col col="12">
                            <v-textarea
                              label="Beschreibung Ihrer Lehrmethoden"
                              :value="coach.methods"
                              disabled
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col col="6">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Social Media
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="coach.social_Facebook"
                              :rules="nameRules"
                              :counter="10"
                              label="Facebook"
                              disabled
                            ></v-text-field>
                          </v-col>

                          <v-col>
                            <v-text-field
                              v-model="coach.social_Twitter"
                              :counter="10"
                              label="Twitter"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="coach.social_Xing"
                              :counter="10"
                              label="Xing"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="coach.social_Instagram"
                              :counter="10"
                              label="Instagram"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="coach.social_Reddit"
                              :counter="10"
                              label="Reddit"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="coach.social_LinkedIn"
                              label="LinkedIn"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Themengebiete
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ul>
                          <li v-for="item in coach.categories" :key="item.name">{{ item.name }}</li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <div class="text-center ma-2">
      <v-snackbar v-model="confirmBar">
        Möchten Sie den Coach wirklich entsperren?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="unlockCoach()">
            Sperrung aufheben
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <div class="text-center ma-2">
      <v-snackbar v-model="activateBar">
        Möchten Sie den Coach nun freischalten?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="activateCoach()">
            Ja, jetzt freischalten
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LockCoach from "../../dialogs/Coaches/LockCoach.vue";
//import { GChart } from "vue-google-charts";

export default {
  created() {
    this.reload();
  },
  mounted() {
    this.$root.$on("reload_coach_details", () => {
      this.reload();
    });
    this.$root.$on("reload_coach_data", () => {
      this.reload();
    });
  },
  data() {
    return {
      dialog: false,
      confirmBar: false,
      activateBar: false,
      nameRules: [
        (v) => !!v || "Name ist ein Pflichtfeld",
        (v) => v.length <= 30 || "Name darf nicht länger als 30 Zeichen sein",
      ],
      emailRules: [
        (v) => !!v || "E-mail ist ein Pflichtfeld",
        (v) => /.+@.+/.test(v) || "Keine gültige E-Mail",
      ],
    };
  },

  components: {
    //GChart,
    LockCoach,
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
    coach() {
      return this.$store.state.coaches.selected.item;
    },
    isLoading() {
      return this.$store.state.coaches.selected.isLoading;
    },
  },
  methods: {
    async reload() {
      if (this.id) {
        await this.$store.dispatch("coaches/getSingle", this.id);
      }
    },
    frontendUrl(imgPath) {
      return process.env.VUE_APP_API_ROUTE + imgPath;
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    Activate() {
      this.activateBar = true;
    },
    closeActivateBar() {
      this.activateBar = false;
    },
    showConfirmBar() {
      this.confirmBar = true;
    },
    closeConfirmBar() {
      this.confirmBar = false;
    },
    activateCoach() {
      let dto = {
        coachId: this.coach.id,
      };
      this.$store.dispatch("coaches/activateRequest", dto).then(() => {
        this.$root.$emit("reload_coaches_data");
        this.closeActivateBar();
        this.$router.push("/coaches");
      });
    },
    unlockCoach() {
      let dto = {
        coachId: this.coach.id,
        Type: 1,
        Reason: "",
      };
      this.$store.dispatch("coaches/lockRequest", dto).then(() => {
        this.$root.$emit("reload_coaches_data");
        this.closeConfirmBar();
        this.$router.push("/coaches");
      });
    },
  },
  filters: {
    todecimal: function (value) {
      return Number.parseFloat(value).toFixed(2);
    },
  },
};
</script>
