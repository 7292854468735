export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null,
        title: null,
    },
    actions: {
        success({ commit }, payload) {
            commit('success', payload);
        },
        error({ commit }, payload) {
            commit('error', payload );
        },
        clear({ commit }) {
            commit('clear');
        }
    },
    mutations: {
        success(state, payload) {
            state.type = 'alert-success';
            state.message = payload.msg;
            state.title = payload.title;
        },
        error(state, payload) {
            state.type = 'alert-danger';
            state.message = payload.msg;
            console.log("payload");
            console.log(payload);
            state.title = payload.title;
        },
        clear(state) {
            state.type = null;
            state.message = null;
            state.title = null;
        }
    }
}