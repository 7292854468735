<template>
    <div>
      <v-container>
        <v-row>
          <v-col>
            <h3>Payment Notifications</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <IPNTable></IPNTable>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
import IPNTable from "../../tables/Digistore/IPNTable.vue"

  export default {
    
    components: {
        IPNTable
    },
  };
  </script>
  