<template>
    <v-container fluid>
            <v-row>
                <v-col col="9">
                    <h3>Meine Coaches</h3>
                    <span>Hier können Sie Ihre Coaches verwalten (Sperren, Freischalten & Bearbeiten)</span>
                    <span>Zum löschen kompletter Benutzeraccounts verwenden Sie bitte <a href="https://sso.coach2find.de">Keycloak</a></span>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12">
                    <CoachesTableOverview></CoachesTableOverview>
                </v-col>
            </v-row>            
    </v-container>
</template>

<script>
import CoachesTableOverview from "./CoachesTableOverview.vue"

export default {    
    data() {
        return {
            GridView: false
        }
    },

    components: {
        CoachesTableOverview
    }
}
</script>
