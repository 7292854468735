import {
    authHeader
} from '../helpers';

export const mailsettingsService = {
    getAll,
    updateExisting
};

const route = '/api/EMailSettings';

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route, requestOptions).then(handleResponse);
}

function updateExisting(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('vue-token');
                location.reload(true);               
            }

            const error = (data && data.message) || response.statusText;
            this.$store.dispatch("alert/error", error);
            return Promise.reject(error);
        }

        return data;
    });
}