<template>
  <v-card>
    <v-toolbar color="primary" dark>Produkt editieren</v-toolbar>
    <v-card-text>
      <v-form v-model="valid">
        <v-container v-if="product">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="product.digistoreProductId"
                :rules="inputRules"
                label="Produkt ID von Digistore"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="product.name"
                :rules="inputRules"
                label="Mitgliedschaft Name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="product.price"
                :rules="inputRules"
                label="Preis im Monat"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="product.digistoreUrl"
                :rules="inputRules"
                label="Digistore URL"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="product.monthDuration"
                :rules="inputRules"
                label="Laufzeit"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-tiptap
                      v-model="product.html"
                      label="Beschreibung"
                    >
                    </v-tiptap>
            </v-col>
            <v-col cols="12" md="12">
              <v-checkbox
                v-model="product.isAvailable"
                :rules="inputRules"
                label="Ist verfügbar"
                required
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="12">
              <v-checkbox
                v-model="product.highlight"
                :rules="inputRules"
                label="Als Highlight markieren"
                required
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn tile color="primary" @click="Save()">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  created() {
    console.info("EditProduct created");
    this.reload();
  },
  mounted() {
    this.$root.$on("reload_product_details", () => {
      this.reload();
    });
    this.$root.$on("reload_product_data", () => {
      this.reload();
    });
  },
  data() {
    return {
      inputRules: [(v) => !!v || "Pflichtfeld"],
      valid: true,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    product() {
      let prod = this.$store.state.products.selected.item;
      return prod;
    },
    isLoading() {
      return this.$store.state.products.selected.isLoading;
    },
  },
  methods: {
    async reload() {
      if (this.id) {
        await this.$store.dispatch("products/getSingle", this.id);
      }
    },
    Close() {
      //this.Reset();
      this.$root.$emit("close_edit_products");
    },
    Save() {
      let dto = {
        id: this.product.id,
        DigistoreProductId: this.product.digistoreProductId,
        Name: this.product.name,
        Price: this.product.price,
        DigistoreUrl: this.product.digistoreUrl,
        MonthDuration: this.product.monthDuration,
        Html: this.product.html,
        IsAvailable: this.product.isAvailable,
        Highlight: this.product.highlight,
      };
      

      this.$store.dispatch("products/update", dto).then(() => {
        this.$root.$emit("reload_products_data");
        this.$router.push({ name: "products" });
      });
    },
  },
};
</script>
